import React from 'react';
import { createComponent, Container, Flex, Title, Slot, For, IntrinsicProps } from 'react-commons';
import PerDevice from '../PerDevice';

import style from './index.module.scss';
import DailyGamesModel from '@/lib/drupal/models/DailyGames';
import useSWR from 'swr';
import Link from 'next/link';

interface ModernAppFooterProps extends IntrinsicProps {
  useAdColumn?: boolean
}

export default createComponent<ModernAppFooterProps>('ModernAppFooter', { style }, function ModernAppFooter ({ className }, props) {
  const { data: games, isLoading: isLoadingGames } = useSWR('featuredSidebarGames', DailyGamesModel.featuredGamesFetcher);
  
  return (
    <footer className={className + (props.useAdColumn ? ' --useAdColumn' : '')}>
      <Container>
        <PerDevice>

          {/* Desktop */}
          <Slot name='desktop'>
            <Flex gap3>
              <div>
                <img 
                  className='ModernAppFooter__Logo' 
                  src='/images/SW25.svg' 
                  alt='Shockwave Logo'
                  aria-hidden 
                />
                <p>
                  Shockwave.com, launched in 1998 by Macromedia, has a rich history as a pioneer in online gaming and interactive media. Initially showcasing Shockwave and Flash player capabilities, it quickly became a leading platform for games, collaborating with iconic brands like Disney and Pixar. Over the decades, Shockwave evolved through strategic mergers, partnerships, and acquisitions, including its exclusive partnership with The Article 19 Group, which has delivered top daily games like the beloved Daily Jigsaw. Now, under the guidance of new leadership and driven by a renewed vision, Shockwave blends nostalgia with innovation, offering a curated mix of classic and modern games. With a strong focus on daily games and a commitment to casual gaming excellence.
                </p>
              </div>
              <div>
                <Title h5 size6>Daily Games</Title>
                <ul>
                  {
                    For(games || [], (item, i) => (
                      <li key={i}>
                        <Link href={item.href}>
                          <a className='Flex Flex--directionRow Flex--alignCenter'>
                            <figure>
                              <img 
                                src={item.mobileIcon} 
                                alt={item.title + ' icon'}
                              />
                            </figure>
                            <span>{item.title}</span>
                          </a>
                        </Link>
                      </li>
                    ))
                  }
                </ul>
              </div>
              <div>
                <Title h5 size6>Shockwave</Title>
                <ul>
                  <li>
                    <Link href='/unlimited'>Subscribe</Link>
                  </li>
                  <li>
                    <Link href='/download/all-games'>Downloadable Games</Link>
                  </li>
                  <li>
                    <Link href='/online/all-games'>Online Games</Link>
                  </li>
                  <li>
                    <Link href='/help'>FAQ</Link>
                  </li>
                  <li>
                    <Link href='/about'>About</Link>
                  </li>
                </ul>
              </div>
            </Flex>
          </Slot>
        
        </PerDevice>
      </Container>
    </footer>
  );
});

import { App, IntrinsicProps, Layout } from 'react-commons';
import { createComponent } from 'react-commons';

import DailyAppHeader from '@/components/DailyAppHeader';
import OrientationOverlay from '@/components/OrientationOverlay';

import style from './index.module.scss';
import { CaptureClickEventContext } from '@/lib/util/captureClickEvent';
import ModernAppFooter from '@/components/ModernAppFooter';
import { useAuthStore } from '@/lib/drupal/stores/auth';

interface DailyGameLayoutProps extends IntrinsicProps {
  pageName?: string
  pageEventSettings?: any
}

export default createComponent<DailyGameLayoutProps>('DailyGameLayout', { style }, function DailyGameLayout ({ className }, props) {
  const [ authState ] = useAuthStore();

  return (
    <CaptureClickEventContext.Provider value={{
      page: props.pageName,
      settings: props.pageEventSettings || {},
    }}>
      <Layout className={className}>
        <App.Header>
          <DailyAppHeader />
        </App.Header>

        <App.Main>
          {props.children}
        </App.Main>

        <App.Footer>
          <ModernAppFooter 
            useAdColumn={!authState.user?.isPremiumUser} 
          />
        </App.Footer>
        
        <OrientationOverlay />
      </Layout>
    </CaptureClickEventContext.Provider>
  );
});

import { Container, createComponent, Flex, For, IconFA, If, IntrinsicProps, Slot } from 'react-commons';
import { faBoltLightning, faBookmark, faCircleHalfStroke, faCircleQuestion, faClock, faExpand, faFlag, faGamepad, faGear, faHeart, faInfoCircle, faMagnifyingGlass, faMoon, faPause, faPencil, faPlay, faRankingStar, faStar, faStopwatch, faSun, faTrophy, faVolumeHigh, faVolumeLow, faVolumeOff, faVolumeXmark } from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion as faCircleQuestionToggled } from '@fortawesome/free-regular-svg-icons';

import style from './index.module.scss';
import { useMessageApiToolbar } from '@/lib/util/messageApi';
import PerDevice from '@/components/PerDevice';
import { ExtendedGameData, FullGameData } from '@/lib/drupal/models/Games';

interface GamePlayerHeader extends IntrinsicProps {
  date: string
  onClickFullScreen?: () => void
  hideDate?: boolean
  game: FullGameData
}

const icons = {
  faBoltLightning,
  faBookmark,
  faCircleHalfStroke,
  faCircleQuestion,
  faCircleQuestionToggled,
  faClock,
  faExpand,
  faFlag,
  faGamepad,
  faGear,
  faHeart,
  faInfoCircle,
  faMagnifyingGlass,
  faMoon,
  faPause,
  faPencil,
  faPlay,
  faRankingStar,
  faStar,
  faStopwatch,
  faSun,
  faTrophy,
  faVolumeXmark,
  faVolumeOff,
  faVolumeLow,
  faVolumeHigh,
};

export default createComponent<GamePlayerHeader>('GamePlayerHeader', { style }, function GamePlayerHeader ({ className }, props) {
  // 08-12-2024
  const shortDate = new Date(props.date).toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  }).replace(/\//g, '-'); 

  // August 12, 2024
  const fullDate = new Date(props.date).toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  const {
    toolbarState,
    sendToolbarClicked,
  } = useMessageApiToolbar({
    game: props.game
  });

  return (
    <header className={className}>
      <Container>
        {
          If(!props.hideDate, () => (
            <Flex tag='aside' pullLeft>
              <PerDevice>
                <Slot name='mobile'>
                  {shortDate}
                </Slot>
                <Slot name='desktop'>
                  {fullDate}
                </Slot>
              </PerDevice>
            </Flex>
          )).Else(() => (
            <Flex tag='aside' pullLeft />
          )).EndIf()
        }
        <Flex>
          <h1>{props.game.title}</h1>
        </Flex>
        <Flex className='GamePlayerHeader__IconToolbar' tag='aside' pullRight>
          {
            If(props.onClickFullScreen, () => (
              <span data-clickable className='--hideMobile'>
                <IconFA icon={faExpand} onClick={props.onClickFullScreen} />
              </span>
            )).EndIf()
          }
          {
            For(toolbarState.items, (item) => (
              <span
                key={item.id}
                onClick={() => sendToolbarClicked(item.id)}
                data-clickable={item.onClick}
                data-disabled={item.disabled}
              >
                { If(item.icon, () => <IconFA icon={icons[ item.icon ]} />).EndIf() }
                { If(item.label, () => <span className='GamePlayerHeader__Label'>{item.label}</span>).EndIf() }
              </span>
            ))
          }
        </Flex>
      </Container>
    </header>
  );
});
